<template>
  <div id="stock-list">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="4">
            月次入出庫詳細（定期便）
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="1"
            @click="reduceMonth"
          >
            <v-icon class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </v-col>
          <v-col cols="2" align-self="center">
            <label>{{ selectedMonth.substring(0, 4) }}年{{ selectedMonth.substring(4, 6) }}月</label>
          </v-col>
          <v-col
            v-if="selectedMonth < currentMonth"
            cols="1"
          >
            <v-icon
              class="me-1"
              @click="addMonth"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-col>
          <v-col
            v-else
            cols="1"
          >
          </v-col>
        </v-row>
      </v-card-title>
      <v-row
        align="center"
        class="mx-0 px-0 mt-1"
      >
        <v-col
          cols="6"
          class="px-5 pt-0 align-self-end"
        >
          <label style="font-size: 16px">定期便コード: {{ $route.query.product_code }}</label>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="6"
          class="px-5 pt-0"
        >
          <div class="d-flex align-center flex-wrap">
            <v-spacer></v-spacer>
            <v-btn
              class="me-3"
              color="secondary"
              outlined
              @click="comeback"
            >
              <v-icon
                size="17"
                class="me-1"
              >
                {{ icons.mdiKeyboardReturn }}
              </v-icon>
              <span>戻る</span>
            </v-btn>
            <v-btn
              color="secondary"
              :loading="exportStatus"
              outlined
              @click="downloadCSV()"
            >
              <v-icon
                size="17"
                class="me-1"
              >
                {{ icons.mdiExportVariant }}
              </v-icon>
              <span>Export</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-3"></v-divider>
      <v-card-text class="pa-0">
        <v-row
          align="center"
          class="ma-0"
        >
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-data-table
              :headers="headers"
              :items="SubsDailyStock"
              group-by="item_code"
              item-key="target_date"
              :items-per-page="-1"
            >
              <template v-slot:[`item.qty_out`]="{ item }">
                <a v-if="item.qty_out>0" style="color:#F44336"
                   href="javascript:void(0);"
                   @click="gotoDelivery(item)"
                >{{ formatData(item.qty_out) }}</a>
                <label v-else> {{ formatData(item.qty_out) }}</label>
              </template>
              <template v-slot:[`item.daily_out`]="{ item }">
                {{ formatData(item.qty_out) }}
              </template>

              <template v-slot:[`group.header`]>
              </template>

              <template v-slot:[`group.summary`]>
                <td style="background: #ffffff; text-align: left">
                  合計
                </td>
                <td style="background: #ffffff; text-align: right">
                  {{ formatData(MonthlyQtyOut) }}
                </td>
                <td style="background: #ffffff; text-align: right; color: #f44336 !important">
                  {{ formatData(MonthlyQty) }}
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable*/
import { mdiKeyboardReturn, mdiChevronLeft, mdiChevronRight, mdiExportVariant } from '@mdi/js'
import { mapState, mapMutations, mapActions } from 'vuex'
/* eslint-disable*/

export default {
  components: {
  },
  data: () => ({
    exportStatus: false,
    icons: {
      mdiKeyboardReturn,
      mdiChevronLeft,
      mdiChevronRight,
      mdiExportVariant,
    },
    currentMonth: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 7)
      .replace('-', ''),

    headers: [
      {
        text: '',
        align: 'left',
        value: 'target_date',
        width: '20%',
        fixed: true,
      },
      // {
      //   text: '入荷数量',
      //   value: 'quantity_in',
      //   align: 'right',
      //   width: '20%',
      //   fixed: true,
      // },
      {
        text: '出荷数量',
        value: 'qty_out',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      {
        text: '当日小計(kg)',
        value: 'daily_out',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      // {
      //   text: '累計在庫(kg)',
      //   value: 'net_weight',
      //   align: 'right',
      //   width: '20%',
      //   fixed: true,
      // },
    ],
  }),

  computed: {
    ...mapState('dailyStockStore', ['SubsDailyStock']),
    selectedMonth() {
      return this.$route.params.month
    },
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    MonthlyQtyOut() {
      let sum = 0
      if (this.SubsDailyStock) {
        this.SubsDailyStock.forEach(ids => {
          sum += ids.qty_out
        })
      }

      return sum
    },
    MonthlyQty() {
      let sum = 0
      if (this.SubsDailyStock) {
        this.SubsDailyStock.forEach(cp => {
          sum += cp.qty_out
        })
      }

      return sum
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...mapActions('dailyStockStore', ['loadSubsDailytStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadSubsDailytStock({
        month: this.$route.params.month,
        product_code:this.$route.query.product_code,
        type:this.$route.query.selTab,
      }).then(() => {
        this.setOverlayStatus(false)
      })
    },

    gotoDelivery(item) {
      this.$router.push({
        name: 'shipping-list',
        query: {
          product_code: this.$route.query.product_code,
          startDate: item.target_date,
          endDate: item.target_date,
        },
      })
    },
    comeback() {
      this.$router.push({
        name: 'stock-monthly',
        params: { month: this.$route.params.month },
        query: { selTab: this.$route.query.selTab },
      })
    },
    reduceMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() - 1)).toISOString().substring(0, 7).replace('-', '')
      console.log('id params:',this.$route.params.id,this.MthStocktabs)
      console.log('id query:',this.$route.query)
      if (selMonth <= this.currentMonth) {
        console.log('id query:',this.$route.query)
        this.$router.push({
          name: 'stock-subs-daily',
          params: {  month: selMonth },
          query: {
            selTab: this.$route.query.selTab,
            product_code: this.$route.query.product_code,
          },
        })
      }
      this.loadData()
    },
    addMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() + 1)).toISOString().substring(0, 7).replace('-', '')
      console.log(this.Stocktabs)
      if (selMonth <= this.currentMonth) {
        this.$router.push({
          name: 'stock-subs-daily',
          params: { month: selMonth },
          query: {
            selTab: this.$route.query.selTab,
            product_code: this.$route.query.product_code,
          },
        })
      }
      this.loadData()
    },

    downloadCSV() {
      // this.exportStatus = true
      // agent.Report.monthlyReportDownload({
      //   date: this.selectedMonth,
      //   data: {
      //     type: 'product',
      //     target_ids: [this.$route.params.id],
      //   },
      // }).then(() => {
      //   this.exportStatus = false
      // })
    },
  },
}
</script>

